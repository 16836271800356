import React from 'react'
import { PageRendererProps } from 'gatsby'
import ScheduleComponent from '../../../components/live/schedule'
import * as session from '../../../util/session'
import Layout from '../../../templates/LiveLayout'
import { withErrorHandler } from '../../../components/errorHandler'

const TrainerWorkouts = (props: PageRendererProps): React.ReactElement => {
  const isTrainer = session.isTrainer()

  if (!isTrainer) {
    return <>{'To access this url log in as trainer'}</>
  }
  return (
    <Layout title="Workout Schedule" location={props.location}>
      <ScheduleComponent location={props.location} />
    </Layout>
  )
}

export default withErrorHandler(TrainerWorkouts)
